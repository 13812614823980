<template>
  <div class="col">
    <div class="card py-2 py-md-0">
      <div
        class="d-block d-md-flex align-items-center"
        v-if="currencies.length"
      >
        <div
          class="col-12 col-md-auto"
          v-for="(c, i) in currencies"
          v-bind:key="c.code"
          v-bind:class="{ 'border-left': i !== 0 }"
        >
          <div class="row align-items-center justify-content-center">
            <div class="col-auto">
              <span class="font-weight-bold font-14">{{ c.code }}</span>
            </div>
            <div class="col-auto p-0">
              <span
                class="font-13 text-muted font-weight-bold cursor-default"
                v-tooltip.bottom="$t('buying')"
                >{{ c.buying }}</span
              >
            </div>
            <div class="col-auto px-2 cursor-default">-</div>
            <div class="col-auto pl-0">
              <span
                class="font-13 text-muted font-weight-bold cursor-default"
                v-tooltip.bottom="$t('selling')"
                >{{ c.selling }}</span
              >
            </div>
          </div>
        </div>
        <div class="text-center mt-2 mt-md-0">
          <div
            v-on:click="showModal"
            class="btn btn-success btn-sm border-left-radius-0"
            v-tooltip.bottom="exchangeRateTooltip"
          >
            <i class="fas fa-crosshairs"></i
            ><span class="d-inline d-md-none ml-2">{{ $t("pinRates") }}</span>
          </div>
        </div>
      </div>
      <div class="py-1 px-2" v-else-if="ready">
        <i class="font-14">{{ $t("exchangeRatesCouldNotBeLoaded") }}</i>
      </div>
      <WeModal v-if="modal" v-on:close="closeModal" modal-width="30">
        <div slot="body">
          <div class="wrapper-card">
            <div v-if="ready">
              <!-- Card Header -->
              <div class="card-header bg-white px-0">
                <div class="row align-items-center justify-content-between">
                  <div class="col-12 col-md-auto text-center">
                    <h5 class="custom-card-title">
                      <i class="fas fa-dollar-sign"></i>
                      {{ $t("exchangeRates") }}
                    </h5>
                  </div>
                  <div class="col-12 col-md-auto text-center">
                    <template v-if="dateTime">
                      <small class="d-block">{{ $t("updateDate") }}</small>
                      <small>{{ dateTime | dateFormat }}</small>
                    </template>
                  </div>
                </div>
              </div>
              <!-- Card Header -->

              <!-- Card Body -->
              <div class="card-body px-0 pb-0">
                <div class="table-responsive" v-if="currencies.length">
                  <table class="table table-bordered">
                    <tr>
                      <th>{{ $t("exchange") }}</th>
                      <th class="text-center bg-light" scope="col">
                        {{ $t("buying") }}
                      </th>
                      <th class="text-center bg-light" scope="col">
                        {{ $t("selling") }}
                      </th>
                    </tr>
                    <tr v-for="(currency, i) in currencies" v-bind:key="i">
                      <th scope="row" class="bg-light text-left">
                        <!-- Flag -->
                        <span
                          class="flag-icon"
                          v-if="currency.flag"
                          v-bind:class="'flag-icon-' + currency.flag"
                        ></span>
                        <!-- Flag -->

                        {{ currency.code }}
                      </th>

                      <!-- Buying -->
                      <td class="text-right">
                        <WeInput
                          class="mb-0 text-right"
                          maxlength="6"
                          v-bind:small-size="true"
                          v-bind:filter-number="true"
                          v-model="currency.tmp.buying"
                          v-bind:placeholder="currency.buying"
                        />
                      </td>
                      <!-- Buying -->

                      <!-- Selling -->
                      <td class="text-right">
                        <WeInput
                          class="mb-0 text-right"
                          maxlength="6"
                          v-bind:small-size="true"
                          v-bind:filter-number="true"
                          v-model="currency.tmp.selling"
                          v-bind:placeholder="currency.selling"
                        />
                      </td>
                      <!-- Selling -->
                    </tr>
                  </table>
                </div>
                <div class="card alert-primary" v-else>
                  <div class="card-body">
                    <i class="fas fa-info-circle"></i>
                    {{ $t("exchangeRatesNotFound") }}
                  </div>
                </div>
                <div class="text-center">
                  <WeSubmitButton
                    v-bind:submit-status="submit"
                    v-on:submit="onSave"
                  />
                </div>
              </div>
              <!-- Card Body -->
            </div>
            <WeLoading v-else />
          </div>
        </div>
      </WeModal>
    </div>
  </div>
</template>
<script>
import { CurrencyDirective, setValue, getValue } from "vue-currency-input";
import { mapActions, mapState } from "vuex";
export default {
  name: "CurrencyWidget",
  directives: {
    currency: CurrencyDirective,
  },
  data() {
    return {
      modal: false,
      ready: false,
      submit: false,
      currencies: [],
      dateTime: null,
    };
  },
  methods: {
    ...mapActions("widget", ["getExchangeRates", "updateExchangeRates"]),
    onSave() {
      let index = 0;
      for (let i = 0; i < this.currencies.length; i++) {
        const c = this.currencies[i];
        c.buying = c.tmp.buying || c.buying;
        c.selling = c.tmp.selling || c.selling;
        index++;
      }
      if (index == this.currencies.length) {
        this.submit = true;
        this.updateExchangeRates({
          currencies: this.currencies,
          onFinish: () => {
            this.submit = false;
            this.$toast.success(this.$t("exchangeRatesUpdated"));
          },
        });
      }
    },
    load() {
      this.ready = false;
      this.getExchangeRates({
        onSuccess: (result) => {
          this.currencies = result.currencies;
          this.dateTime = result.lastUpdate;
          this.ready = true;
        },
      });
    },
    showModal() {
      this.load();
      this.modal = true;
    },
    closeModal() {
      this.modal = false;
    },
    getTodayDateTime(date) {
      const dateTime = new Date(date);
      return dateTime
        .toLocaleString(this.langCode())
        .replace(",", "")
        .replace(/:.. /, " ");
    },
    langCode() {
      return localStorage.getItem("_lang_code") || "tr-TR";
    },
  },
  computed: {
    ...mapState({
      currency: (state) => state.widget.exchangeRate,
    }),
    todayDateTime() {
      return this.getTodayDateTime(new Date());
    },
    exchangeRateTooltip() {
      let result = this.$t("pinRates");
      result += " <br>------<br> ";
      result += this.$t("updateDate");
      result += " <br> ";
      result += this.getTodayDateTime(this.dateTime);
      return result;
    },
  },
  mounted() {
    this.load();
  },
};
</script>
<style lang="scss" scoped>
.wrapper-card {
  min-height: 250px;
  transition: 0.2s all;
}
</style>
